<template>
  <div>
    <el-dialog
      title="重新发布物理房型"
      :visible.sync="dialogFormVisible"
      width="710px"
     
      :close-on-click-modal="false"
    >
      <div>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedlist"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox v-for="item in horseVestBaseVoList" :label="item.id" :key="item.id">
             {{item.horseName}}( {{item.platformId | formatPlatformId}})
              </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handAddData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMatchingVestAndRoomTypeInfo_api,sendRoomType_api } from "../../../apis/room";
export default {
  data() {
    return {
      dialogFormVisible: false,
      horseVestBaseVoList: "",
      checkAll: false,
      isIndeterminate: false,
      checkedlist:[],
      horseVestBaseIdVoList:[],
      roomTypeId:'',
    };
  },
  filters:{
      formatPlatformId(val){
         if(val==1){
           return "艺龙"
         }else if(val==2){
           return "飞猪"
         }else if(val==3){
           return "美团"
         }else if(val==4){
           return "去哪儿"
         }else if(val==5){
           return "携程"
         }else if(val==6){
           return "百度"
         }  else if(val==7){
           return "萝卜"
         }else if(val==8){
           return "天下房仓"
         }
      }
  },
  methods: {
    async open(hotelSonId, roomTypeId) {
        this.roomTypeId=roomTypeId
        this.checkedlist=[]
      let { code, data } = await getMatchingVestAndRoomTypeInfo_api({
        hotelSonId: hotelSonId,
        roomTypeId: roomTypeId,
      });
      if (code == 0) {
          this.horseVestBaseIdVoList=[]
        this.horseVestBaseVoList = data.horseVestBaseVoList;
        data.horseVestBaseVoList.forEach((item)=>{
            this.horseVestBaseIdVoList.push(item.id)
        })
         this.dialogFormVisible = true;
      }
    },
    cleanFn() {
      this.dialogFormVisible = false;
    },
    async handAddData() {
      let {code,data}=await sendRoomType_api({
          roomTypeId:this.roomTypeId,
          vestIds:this.checkedlist.join(',')
      })
      if(code==0){
            this.$message.success(data);
             this.dialogFormVisible = false;
      }
    },
    handleCheckAllChange(val){
         this.checkedlist = val ? this.horseVestBaseIdVoList : [];
         this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value){
       let checkedCount = value.length;
        this.checkAll = checkedCount === this.horseVestBaseVoList.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.horseVestBaseVoList.length;
    }

  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>